import { Link } from "react-router-dom";
import logo from "../logo.svg";

export default function Navbar() {
  return (
    <>
      <nav className={`navbar p-0 relative lg:mb-8xl mb-14`}>
        <div className="2xl:container mx-auto px-8 flex justify-between relative">
          <div className="logo max-w-36">
            <Link to={"/"}>
              <img src={logo} className="w-full block" alt="logo" />
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}
