import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import Nav from "./components/Nav";
import Aside from "./components/Aside";
import SideMenu from "./components/SideMenu";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Resume from "./pages/Resume";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";

const App = () => {
  const [mobileOnly, setMobileOnly] = useState(window.innerWidth < 1024);
  const updateMobileOnly = () => {
    setMobileOnly(window.innerWidth < 1024);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMobileOnly);
    return () => {
      window.removeEventListener("resize", updateMobileOnly);
    };
  }, []);
  return (
    <Router>
      <Nav />
      <div className="relative mt-4">
        <div className="2xl:container mx-auto px-4 flex max-xl:flex-wrap">
          {!mobileOnly && <Aside />}
          <div className="xl:w-7/12 lg:w-4/6 w-full px-4 max-xl:order-3">
            <div className="bg-white lg:p-10 px-5 py-6 rounded-2xl">
              <Routes>
                {mobileOnly && <Route exact path="/" element={<Aside />} />}
                {mobileOnly && <Route exact path="/about" element={<Home />} />}
                {!mobileOnly && <Route exact path="/" element={<Home />} />}
                <Route exact path="/resume" element={<Resume />} />
                <Route exact path="/projects" element={<Projects />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <div className="lg:-mx-10 lg:-mb-10 -mx-5 -mb-5 text-center lg:p-6 p-5 text-prime lg:text-base text-xs">
                © 2024 All Rights Reserved by Shihab.
              </div>
            </div>
          </div>
          <SideMenu />
        </div>
      </div>
    </Router>
  );
};

export default App;
